import React, { useState } from "react"
import { navigate } from "gatsby"
import { Container, Row, Col, Card, ButtonGroup, Button } from "react-bootstrap"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import locales from "../locales/index.esm"
import config from "../../siteconfig.esm"

const locale = process.env.GATSBY_LOCALE
const labels = locales[locale]

const Quiz = props => {
  const { questions, siteData, cta } = props.pageContext

  const [index, setIndex] = useState(0)
  const [quiz] = useState(props.pageContext.quiz)
  const [question, setQuestion] = useState(props.pageContext.questions[0])
  const [answers, setAnswers] = useState([])
  const [selected, setSelected] = useState({})
  const [valid, setValid] = useState(false)
  const [showInvalid, setShowValid] = useState(false)

  function chooseAnswer(evt) {
    const ans = answers.slice()
    const sel = question.relationships.field_answers.find(a => a.id === evt.target.value)
    const current = ans.find(a => a.index === index)
    if (current) {
      ans.answer = sel
    } else {
      ans.push({ index: index, question: question, answer: sel })
    }
    setSelected(sel)
    setAnswers(ans)
    setValid(true)
    setShowValid(false)
  }

  function previous() {
    const i = answers.findIndex(a => a.index === index)
    const answer = ~i ? answers[i - 1] : answers.slice(-1)[0]
    setSelected(answer.answer)
    setIndex(answer.index)
    setQuestion(answer.question)
    setValid(true)
    setShowValid(false)
  }

  function next() {
    if (!valid) {
      setShowValid(true)
      return
    }
    // transition?
    const answer = answers.find(a => a.index === index)
    if (answer.answer.field_next_question) {
      const question = questions.find(
        q => q.field_question_order === answer.answer.field_next_question
      )
      setIndex(answer.answer.field_next_question)
      setQuestion(question)
      const returning = answers.find(a => a.index === answer.answer.field_next_question)
      if (returning) {
        setSelected(returning.answer)
        setValid(true)
      } else {
        setValid(false)
        setSelected({})
      }
    } else {
      // go to report
      const query =
        `?quiz=${quiz.id}&` + answers.map(a => `${a.question.id}=${a.answer.id}`).join("&")
      navigate(props.path.replace("quiz", config.paths[locale].report) + `${query}`, {
        state: { answers: answers },
      })
    }
  }

  function NextButton() {
    if (index) {
      return (
        <ButtonGroup aria-label="previous-next button">
          <Button variant="secondary" onClick={previous}>
            {labels.previous}
          </Button>
          <Button variant="primary" onClick={next}>
            {labels.next}
          </Button>
        </ButtonGroup>
      )
    } else {
      return (
        <ButtonGroup aria-label="next button">
          <Button variant="primary" onClick={next}>
            {labels.next}
          </Button>
        </ButtonGroup>
      )
    }
  }

  return (
    <Layout siteData={siteData} seo={{ title: siteData.pageTitles.quiz }}>
      <PageHeader crumbs={props.pageContext.breadcrumb} />
      <Container className="quiz not-front">
        <Row>
          <Col md="12">
            <Card.Body className="p-0 mb-5 w-75">
              <h1 className="h1">{quiz.title}</h1>
              <div className="card-text">
                <span dangerouslySetInnerHTML={{ __html: quiz.description }}></span>
              </div>
            </Card.Body>
          </Col>
        </Row>
        <Row>
          <Col md="12" className={showInvalid && "error"} aria-live="assertive">
            <Card
              className={`card-quiz mb-4 d-flex justify-content-between align-self-start ${config.style.jumbotronBase}`}
            >
              <Card.Header className="border-0 py-5">
                <h2
                  className="h2 mt-0"
                  dangerouslySetInnerHTML={{ __html: question.field_question_text.processed }}
                ></h2>
                <img
                  className="section-card"
                  src={
                    cta.relationships?.field_cta_background_image?.localFile.childImageSharp.fluid
                      .src ||
                    "/" + config.style.jumbotronImage ||
                    ""
                  }
                  alt={cta.field_cta_background_image?.alt || ""}
                  aria-hidden="true"
                />
              </Card.Header>
              <Card.Body>
                {question.relationships.field_answers.map((answer, index) => (
                  <Card.Text key={answer.id}>
                    <label>
                      <input
                        type="radio"
                        name={`q${index}`}
                        value={answer.id}
                        checked={selected.id === answer.id}
                        onChange={chooseAnswer}
                      />{" "}
                      {answer.field_answer_text}
                    </label>
                  </Card.Text>
                ))}
              </Card.Body>
              <Card.Footer className="d-flex justify-content-between align-items-center mt-3">
                <NextButton />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Quiz
